<template>
  <div>
    <div class="relative w-full flex flex-row mb-4">
      <h1 class="text-lg inline-flex items-center">
        <span>Generar comisión: <strong>{{ commission.account.name }}</strong></span>
      </h1>
      <el-button class="absolute right-0" title="Cerrar" @click="closeCommission">
        X
      </el-button>
    </div>

    <div>
      <div>
        <div class="w-full flex flex-row border">
          <div class="w-5/12 p-4 border-r">
            <div class="w-full border-b pb-3 flex flex-row">
              <div class="w-9/12">
                <strong>Configuracion</strong>
              </div>
            </div>
            <div class="mt-4 border-b pb-3">
              <strong>Fechas</strong>
              <el-date-picker type="daterange" placeholder="Fechas" v-model="commission.dates"
                style="width: 100%; justify-content: center;" range-separator="-" @change="getOrders"
                start-placeholder="Start date" end-placeholder="End date" unlink-panels></el-date-picker>
            </div>
            <div class="mt-4 border-b pb-3">
              <strong>Ordenes</strong>
              <div class="flex flex-row">
                <el-radio-group v-model="ordersConfig" @change="handleOrdersConfigChange">
                  <el-radio label="all">Todos</el-radio>
                  <el-radio label="services">Servicios</el-radio>
                  <el-radio label="products">Productos</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="mt-4 border-b pb-3">
              <strong>Ventas</strong>
              <div class="tooltip-container">
                <i class="bx bx-info-circle info-icon"></i>
                <div class="tooltip-content">
                  <p>Obten el Valor de Comision de las Ordenes por Venta Total o por Venta Neta</p>
                  <p>* Venta Total se calcula sin los gastos de una orden</p>
                  <p>* Venta Neta se calcula junto con gastos de una orden</p>
                </div>
              </div>
              <span class="flex flex-col text-sm">

              </span>
              <div class="flex flex-row">
                <el-radio-group v-model="salesConfig">
                  <el-radio label="total">Venta Total</el-radio>
                  <el-radio label="partial">Venta Neta</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="mt-4 border-b pb-3 w-full flex flex-row justify-between">
              <strong>Comision</strong>
              <el-input-number v-model="commission.percentage" :min="0" :max="100"></el-input-number>
            </div>
          </div>
          <div class="w-7/12 p-4 flex flex-col justify-between">
            <div>
              <div class="w-full border-b pb-3 flex flex-row">
                <div class="w-9/12">
                  <strong>Ordenes</strong>
                </div>
              </div>
              <div v-if="orders.length > 0" class="mt-4 block w-full overflow-x-auto">
                <table class="items-center w-full border-collapse">
                  <thead>
                    <tr class="px-2 border-b-2">
                      <th class="py-3 font-bold border-l-0 border-r-0 whitespace-nowrap w-1/12 text-center">
                      </th>
                      <th class="py-3 font-bold border-l-0 border-r-0 whitespace-nowrap w-8/12 text-left">
                        #
                      </th>
                      <th class="py-3 font-bold border-l-0 border-r-0 whitespace-nowrap w-3/12 text-left">
                        Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(order, index) in orders" :key="order.id">
                      <!-- Colapsable para cada fila -->
                      <td colspan="4" class="border-t">
                        <el-collapse :key="order.id" accordion>
                          <el-collapse-item :name="index">
                            <template #title>
                              <!-- Estructura alineada con el encabezado -->
                              <table class="w-full">
                                <tr>
                                  <td class="w-1/12 text-center">
                                    <el-checkbox :value="isOrderSelected(order)" @change="toggleOrder(order)">
                                    </el-checkbox>
                                  </td>
                                  <td class="w-8/12">{{ order.reference }}</td>
                                  <td class="w-3/12 text-right pr-4">{{
                                    moneyFormat(order.totalAmount || 0) }}</td>
                                </tr>
                              </table>
                            </template>

                            <!-- Contenido del colapsable -->
                            <p><strong>Detalles:</strong></p>
                            <div class="px-4 py-2" style="column-count: 2">
                              <p>
                                <strong>Candidad de Dispositivos: </strong>
                                {{ order.orderDevices.length }}
                              </p>
                              <p>
                                <strong>Candidad de Servicios: </strong>
                                {{order.orderDevices.reduce((acc, curr) => acc +
                                  curr.orderDeviceServices.length, 0)}}
                              </p>
                              <p>
                                <strong>Fecha:</strong>
                                {{ dateFormat(order.createdAt) }}
                              </p>
                              <p>
                                <strong>Cliente:</strong>
                                {{ order.client.email }}
                              </p>
                              <!-- Más detalles -->
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="text-center" style="height: 100%;align-content: center;">
                No hay ordenes creadas o asignadas al usuario dentro del rango de fechas seleccionado
              </div>
            </div>
            <div class="mt-4 pt-3 border-t flex flex-row w-full justify-between">
              <strong>Total de Comision</strong>
              <span>{{ moneyFormat(calculatedCommission) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full text-right mt-6">
      <sa-button @click="openPaymentDialog">Agregar como Gasto</sa-button>
    </div>

    <el-dialog append-to-body :visible.sync="showPaymentDialog">
      <payment-expense-form @save:payment="createExpense"></payment-expense-form>
    </el-dialog>
  </div>
</template>
<style scoped>
.custom-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 columnas */
  gap: 10px;
  /* Espacio entre columnas */
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.info-icon {
  font-size: 24px;
  color: #007bff;
}

.tooltip-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 120%;
  /* Ajusta según la posición deseada */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  bottom: 100%;
  /* Puntero arriba del tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
</style>
<script>
import _ from 'lodash';
import AdminService from '@/services/AdminService';
import ExpenseService from '@/services/ExpenseService';
import { getPostRequest } from '@/components/dialogs/Expense/utils';
import { ExpenseType, ExpenseCategory } from '@/constants';
import numbers from '@/utils/numbers';

export default {
  name: 'Commission',
  props: {
    commission: {
      type: Object,
      default: () => { },
    }
  },
  components: {
    PaymentExpenseForm: () => import('@/components/dialogs/Expense/components/PaymentExpenseForm'),
  },
  data() {
    return {
      step: 0,
      ordersConfig: 'all',
      salesConfig: 'total',
      all: true,
      filters: [],
      allOrders: [],
      selectedOrders: [],
      showPaymentDialog: false,
    }
  },
  computed: {
    orders() {
      return this.allOrders;
    },
    calculatedCommission() {
      if (this.salesConfig === 'partial') {
        const partial = this.selectedOrders.reduce((orderCost, order) => {
          const costs = order.orderDevices.reduce((deviceCost, device) => {
            const services = device.orderDeviceServices
              .reduce((serviceCost, service) => serviceCost + service.cost, 0);

            const products = order.orderProducts.reduce((orderProductCost, product) => orderProductCost + product.cost, 0)
              + device.orderDeviceProducts.reduce((deviceProductCost, product) => deviceProductCost + product.cost, 0);

            return deviceCost + services + products;
          }, 0);

          return orderCost + (order.totalAmount - costs);
        }, 0);

        return this.commission.percentage * partial / 100;
      }

      return this.commission.percentage * this.selectedOrders.reduce((acc, curr) => acc + curr.totalAmount, 0) / 100;
    },
  },
  methods: {
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    dateFormat(value) {
      const format = this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(value).format(format);
    },
    closeCommission() {
      this.$emit('close');
      this.step = 0;
      this.accordion = 'Services';
      this.all = false;
      this.allOrders = [];
      this.selectedOrders = [];
    },
    handleOrdersConfigChange(value) {
      if (value === 'all') {
        this.all = true;
        this.selectAll();
      } else if (value === 'services') {
        this.all = false;
        this.selectAllServices(true);
        this.selectAllProducts(false);
      } else if (value === 'products') {
        this.all = false;
        this.selectAllServices(false);
        this.selectAllProducts(true);
      }
    },
    selectAll() {
      if (this.all) {
        this.selectedOrders = this.allOrders;
      } else {
        this.selectedOrders = [];
      }
    },
    selectAllServices(value) {
      if (value) {
        this.selectedOrders = this.allOrders.filter((order) =>
          order.orderDevices.some((device) => device.orderDeviceServices && device.orderDeviceServices.length > 0)
        );
      } else {
        this.selectedOrders = this.selectedOrders.filter((order) =>
          !order.orderDevices.some((device) => device.orderDeviceServices && device.orderDeviceServices.length > 0)
        );
      }
    },
    selectAllProducts(value) {
      if (value) {
        this.selectedOrders = this.allOrders.filter((order) =>
          order.orderDevices.some((device) => device.orderDeviceProducts && device.orderDeviceProducts.length > 0) ||
          order.orderProducts && order.orderProducts.length > 0
        );
      } else {
        this.selectedOrders = this.selectedOrders.filter((order) =>
          !order.orderDevices.some((device) => device.orderDeviceProducts && device.orderDeviceProducts.length > 0) &&
          (!order.orderProducts || order.orderProducts.length === 0)
        );
      }
    },
    toggleOrder(orderItem) {
      const exists = this.selectedOrders.some((order) => order.id === orderItem.id);
      if (exists) {
        this.selectedOrders = this.selectedOrders.filter((order) => order.id !== orderItem.id);
      } else {
        this.selectedOrders = [...this.selectedOrders, orderItem];
      }
    },
    isOrderSelected(orderItem) {
      return this.selectedOrders.some((order) => order.id == orderItem.id);
    },
    async getOrders() {
      const [start, end] = this.commission.dates;

      this.allOrders = await AdminService.UsersAndPermissions.getOrders({
        accountId: this.commission.account.id,
        startDate: this.$moment(start).format('MM/DD/YYYY'),
        endDate: this.$moment(end).format('MM/DD/YYYY'),
      });
      this.all = true;
      this.selectAll();
    },
    openPaymentDialog() {
      if (!this.commission > 0 || _.isEmpty(this.selectedOrders)) {
        this.$toastr.e("Falta llenar el porcentaje de comision o seleccionar ordenes");
        return;
      }
      this.$confirm(
        `Se creara un nuevo gasto con fecha de hoy a el listado de tus gastos?`, {
        type: 'info',
        confirmButtonText: 'Ok',
        cancelButtonText: 'No',
      }).then(async () => {
        try {
          await AdminService.UsersAndPermissions.updateCommission({
            accountId: this.commission.account.id,
            commission: this.commission.percentage,
          });
          this.commission.account.commission = this.commission.percentage;
        } catch (error) {
          this.$toastr.e("No se pudo actualizar el valor de porcentaje de comision");
        } finally {
          this.showPaymentDialog = true;
        }
      });
    },
    async createExpense(paymentMethod) {
      try {
        this.showPaymentDialog = false;

        const data = getPostRequest(
          ExpenseType.Dynamic,
          {
            category: ExpenseCategory.Other,
            subcategory: `Pago de Comision: ${this.commission.account.name}`,
            amount: this.calculatedCommission,
            date: new Date(),
            isExpenseSettled: true,
          },
          [], // formList empty -> only used for Inventory type Expenses
          paymentMethod,
        );
        await ExpenseService.create({ ...data, businessInfoId: this.$store.state.business.id });
        this.$toastr.s("Se creo con exito");
        this.$emit('expense:created');
        this.reset();
      } catch (error) {
        console.log(error);
        this.$toastr.e("Algo salio mal");
      }
    },
    reset() {
      this.ordersConfig = null;
      this.salesConfig = 'total';
      this.allOrders = [];
      this.selectedOrders = [];
    },
  },
}
</script>